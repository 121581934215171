
import algosdk from 'algosdk';


window.ASSETSTORE = window.ASSETSTORE || {};
ASSETSTORE = {

    init: function(){
        console.log("ASSETSTORE.init");
    },


    btn_escrow: async function(e) {
        e.preventDefault();
        if(!ALGOBASE.user_address){
            return ALGOBASE.askWallet("btn_escrow");
        }

        const algodClient = ALGOBASE.algoClient();
        let transactionParamsPromise = algodClient.getTransactionParams().do();
        ASSETSTORE.lastParams = await transactionParamsPromise;

        var $this = $(this),
            bigfun_id = $this.data("bigfun"),
            address = $this.data("address"),
            mint_price = $this.data("price");


        $("#modal-asset-escrow").data("bigfun",bigfun_id);
        $("#modal-asset-escrow").data("address",address);
        $("#modal-asset-escrow").data("price",mint_price);


        $("#modal-asset-escrow .working").hide();

        $("#modal-asset-escrow .btn-asset-escrow-confirm").show();
        $("#modal-asset-escrow #modal-asset-escrow-form").show()
        $("#modal-asset-escrow #modal-asset-escrow-success").hide()
        $("#modal-asset-escrow #modal-asset-escrow-error").hide()

        //$("#modal-asset-escrow #modal-asset-escrow-id").html(asset_id)
        $("#modal-asset-escrow #modal-asset-escrow-wallet").html(address)
        $("#modal-asset-escrow #modal-asset-escrow-price").html(mint_price)


        $("#modal-asset-escrow").modal("show");
    },



    btn_escrow_confirm: async function(e) {
        e.preventDefault();

        $("#modal-asset-escrow .btn-asset-escrow-confirm").hide();
        $("#modal-asset-escrow .working").show();

        var bigfun_id = $("#modal-asset-escrow").data("bigfun");
        var address = $("#modal-asset-escrow").data("address");
        var mint_price = $("#modal-asset-escrow").data("price");

        var payprice = (mint_price * 1000 * 1000);

        console.log("ASSETSTORE.btn_escrow_confirm", bigfun_id, address, mint_price, payprice);

        var from_address = ALGOBASE.user_address;

        console.log("txEscrow-inline",bigfun_id, address, mint_price, payprice)



        var data = {
            network: ( ALGOBASE.is_testnet ? "testnet" : "mainnet" ),
            bigfun_id: bigfun_id,
            from_address: from_address,
            store_address: address,
        }


        const transactionParams = ASSETSTORE.lastParams;

        var txns = [];

        //const txn = algosdk.makePaymentTxnWithSuggestedParams(from_address, address, payprice, undefined, undefined, transactionParams);
        const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: from_address,
            to: address,
            amount: payprice,
            suggestedParams: transactionParams
        });
        txns.push(txn)


        var blobs = false
        try {

            blobs = await ALGOBASE.wallet_sign(txns,from_address)


        } catch (e) {


            logerror("btn_escrow_confirm",e)


            $("#modal-asset-escrow .working").hide();

            $("#modal-asset-escrow #modal-asset-escrow-form").hide()
            $("#modal-asset-escrow #modal-asset-escrow-error").show()

            var error_text = "Wallet Signing Error";
            $("#modal-asset-escrow #modal-asset-escrow-error-text").html(error_text)

            return;
        } finally {
            //return;
        }





        console.log("blobs")
        console.log(blobs)


        data.first_round = transactionParams.firstRound
        data.blobs = blobs


        console.log("posting data")
        console.log(data)


        $.ajax({
            type: 'POST',
            url: "/api/algorand/escrow",
            data: data,
            dataType: 'json',
            success: function(response) {
                console.log("escrow api call respone")
                console.log(response);

                $("#modal-asset-escrow .working").hide();

                if(response.status=="good"){
                    $("#modal-asset-escrow #modal-asset-escrow-form").hide()
                    $("#modal-asset-escrow #modal-asset-escrow-success").show()

                    var link = "<a href='"+response.tx_link+"' target='_blank' rel='noopener noreferrer'>"+ALGOBASE.shortTxHtml(response.tx)+"</a>"

                    $("#modal-asset-escrow #modal-asset-escrow-success-tx").html(link)


                    $("#modal-asset-escrow .btn-close").on("click",function(e){
                        e.preventDefault();
                        window.location.reload();
                    })
                    $(".btn-asset-escrow-close").on("click",function(e){
                        e.preventDefault();
                        window.location.reload();
                    })

                }else{

                    $("#modal-asset-escrow #modal-asset-escrow-form").hide()
                    $("#modal-asset-escrow #modal-asset-escrow-error").show()

                    var error_text = response.output;
                    $("#modal-asset-escrow #modal-asset-escrow-error-text").html(error_text)
                }




            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.error("escrow ERROR")
                console.log(xhr.statusText);
                console.log(xhr.responseText);
                console.log(ajaxOptions);
                console.log(thrownError);

                $("#modal-asset-escrow #modal-asset-escrow-form").hide()
                $("#modal-asset-escrow #modal-asset-escrow-error").show()

                var error_text = thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText;
                $("#modal-asset-escrow #modal-asset-escrow-error-text").html(error_text)
                $("#modal-asset-escrow .working").hide();
            }
        });


    },






    btn_buy: async function(e) {
        e.preventDefault();



        var $this = $(this),
            bigfun_id = $this.data("bigfun"),
            bigfun_name = $this.data("bigfun-name"),
            address = $this.data("address"),
            mint_price = $this.data("price");

        console.log("btn_buy",$this,bigfun_id,bigfun_name,address,mint_price)

        if(bigfun_id){
            $("#modal-asset-buy").data("bigfun",bigfun_id);
            $("#modal-asset-buy").data("address",address);
            $("#modal-asset-buy").data("price",mint_price);


            $("#modal-asset-buy .btn-bigfun-claim").hide();
            $("#modal-asset-buy .working").hide();
            $("#modal-asset-buy #modal-asset-buy-pending").hide();



            $("#modal-asset-buy .btn-asset-buy-confirm").show();
            $("#modal-asset-buy #modal-asset-buy-form").show()
            $("#modal-asset-buy #modal-asset-buy-success").hide()
            $("#modal-asset-buy #modal-asset-buy-error").hide()

            $("#modal-asset-buy #modal-asset-buy-bigfun-name").html(bigfun_name)
            //$("#modal-asset-buy #modal-asset-buy-wallet").html(address)
            $("#modal-asset-buy #modal-asset-buy-price").html(mint_price)
        }





        if(!ALGOBASE.user_address){
            console.log("missing addresss - need to connect")
            return ALGOBASE.askWallet(e,"btn_buy");
        }

        const algodClient = ALGOBASE.algoClient();
        let transactionParamsPromise = algodClient.getTransactionParams().do();
        ASSETSTORE.lastParams = await transactionParamsPromise;


        // var algoapplink = "algorand://"+address+"?amount="+(mint_price * 1000 * 1000)+"&note=1234-1234"
        // $("#modal-asset-buy .btn-asset-buy-app").attr('href',algoapplink)
        // $("#modal-asset-buy .btn-asset-buy-app h6").text(algoapplink)


        $("#modal-asset-buy").modal("show");
    },


    btn_buy_confirm: async function(e) {
        e.preventDefault();

        $("#modal-asset-buy .btn-asset-buy-confirm").hide();
        $("#modal-asset-buy .working").show();

        var bigfun_id = $("#modal-asset-buy").data("bigfun");
        var address = $("#modal-asset-buy").data("address");
        var mint_price = $("#modal-asset-buy").data("price");

        var payprice = (mint_price * 1000 * 1000);

        console.log("ASSETSTORE.btn_buy_confirm", bigfun_id, address, mint_price, payprice);

        //ASSETSTORE.txPurchase(asset_id,amount,ALGOBASE.user_address,address,buyprice);
        var from_address = ALGOBASE.user_address;

        console.log("txPurchase-inline",bigfun_id, address, mint_price, payprice)



        var data = {
            network: ( ALGOBASE.is_testnet ? "testnet" : "mainnet" ),
            bigfun_id: bigfun_id,
            from_address: from_address,
            store_address: address,
        }


        const transactionParams = ASSETSTORE.lastParams;



        var txns = [];

        //const txn = algosdk.makePaymentTxnWithSuggestedParams(from_address, address, payprice, undefined, undefined, transactionParams);
        const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: from_address,
            to: address,
            amount: payprice,
            suggestedParams: transactionParams
        });
        txns.push(txn)



        $("#modal-asset-buy #modal-asset-buy-pending").show();

        var blobs = false
        try {

            blobs = await ALGOBASE.wallet_sign(txns,from_address)
        } catch (e) {
            logerror("btn_buy_confirm",e)

            $("#modal-asset-buy .working").hide();

            $("#modal-asset-buy #modal-asset-buy-form").hide()
            $("#modal-asset-buy #modal-asset-buy-error").show()

            var error_text = "Wallet Signing Error";
            $("#modal-asset-buy #modal-asset-buy-error-text").html(error_text)

            return;
        } finally {
            //return;
        }




        data.first_round = transactionParams.firstRound
        data.blobs = blobs


        console.log("posting data")
        console.log(data)


        $.ajax({
            type: 'POST',
            url: "/api/algorand/bigfun",
            data: data,
            dataType: 'json',
            success: function(response) {
                console.log("buy api call respone")
                console.log(response);

                $("#modal-asset-buy .working").show();

                if(response.status=="good"){

                    $("#modal-asset-buy #modal-asset-buy-pending").hide();

                    $("#modal-asset-buy #modal-asset-buy-form").hide()
                    $("#modal-asset-buy #modal-asset-buy-success").show()

                    var link = "<a href='"+response.tx_link+"' target='_blank' rel='noopener noreferrer'>"+ALGOBASE.shortTxHtml(response.tx)+"</a>"

                    $("#modal-asset-buy #modal-asset-buy-success-tx").html(link)

                    ASSETSTORE.watchIntCount = 0;
                    ASSETSTORE.watchTxId = response.tx;
                    ASSETSTORE.watchIntId = setInterval(ASSETSTORE.live_watch_mint, 2000);

                }else{

                    $("#modal-asset-buy #modal-asset-buy-form").hide()
                    $("#modal-asset-buy #modal-asset-buy-error").show()

                    var error_text = response.output;
                    $("#modal-asset-buy #modal-asset-buy-error-text").html(error_text);

                    $("#modal-asset-buy .working").hide();
                }




            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.error("buy ERROR")
                console.log(xhr.statusText);
                console.log(xhr.responseText);
                console.log(ajaxOptions);
                console.log(thrownError);

                $("#modal-asset-buy .working").hide();

                $("#modal-asset-buy #modal-asset-buy-form").hide()
                $("#modal-asset-buy #modal-asset-buy-error").show()

                var error_text = thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText;
                $("#modal-asset-buy #modal-asset-buy-error-text").html(error_text)
            }
        });


    },


    watchHtmlHash: false,
    watchTxId: false,
    watchIntId: false,
    watchIntCount: 0,
    // # ASSETSTORE.live_watch_mint(response)

    updateHtmlHash: function(selector,hash,html){
        if(hash != ASSETSTORE.watchHtmlHash){
            ASSETSTORE.watchHtmlHash = hash;
            $(selector).html(html);
            console.log("new html",hash,selector);
        }
    },

    live_watch_mint: function(){

        var url = "/api/algorand/bigfun/"+ASSETSTORE.watchTxId;

        ASSETSTORE.watchIntCount = ASSETSTORE.watchIntCount + 1;
        console.log("live_watch_mint",ASSETSTORE.watchIntCount,url)

        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: function(response) {
                console.log("live_watch_mint respone")
                console.log(response);

                $("#modal-asset-buy #modal-asset-status-render").html(response.data.render_status)
                $("#modal-asset-buy #modal-asset-status-mint").html(response.data.mint_status)

                // if(response.data.render_status == "ready"){
                //     //$("#modal-asset-buy #modal-asset-status-preview").attr("src", response.data.preview_url)
                //     ASSETSTORE.updateHtmlHash("#modal-asset-buy #modal-asset-status-preview-wrap",response.data.preview_html_hash,response.data.preview_html)
                //     $("#modal-asset-buy #modal-asset-status-preview-wrap").show();
                // }

                if(response.data.mint_status == "minted"){
                    clearInterval(ASSETSTORE.watchIntId);
                    console.log("MINTED SHOW CLAIM");

                    ASSETSTORE.updateHtmlHash("#modal-asset-buy #modal-asset-status-preview-wrap",response.data.preview_html_hash,response.data.preview_html)
                    $("#modal-asset-buy #modal-asset-status-preview-wrap").show();



                    $("#modal-asset-buy .btn-bigfun-claim").data("bigfunmint",ASSETSTORE.watchTxId);
                    $("#modal-asset-buy .btn-bigfun-claim").data("asa",response.data.asset_id);
                    $("#modal-asset-buy .btn-bigfun-claim").data("address",response.data.escrow_addresss);
                    $("#modal-asset-buy .btn-bigfun-claim").show();

                    $("#modal-asset-buy .working").hide();
                }


            },
            error: function(xhr, ajaxOptions, thrownError) {
                clearInterval(ASSETSTORE.watchIntId);
                console.error("live_watch_mint ERROR")
                console.log(xhr.statusText);
                console.log(xhr.responseText);
                console.log(ajaxOptions);
                console.log(thrownError);

                $("#modal-asset-buy .working").hide();
                $("#modal-asset-buy #modal-asset-buy-form").hide()
                $("#modal-asset-buy #modal-asset-buy-error").show()

                var error_text = thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText;
                $("#modal-asset-buy #modal-asset-buy-error-text").html(error_text)
            }
        });



    },


    btn_claim: async function(e) {
        e.preventDefault();
        if(!ALGOBASE.user_address){
            return ALGOBASE.askWallet("btn_claim");
        }

        const algodClient = ALGOBASE.algoClient();
        let transactionParamsPromise = algodClient.getTransactionParams().do();
        ASSETSTORE.lastParams = await transactionParamsPromise;

        var $this = $(this),
            bigfun_hash = $this.data("bigfunmint"),
            asset_id = $this.data("asa"),
            address = $this.data("address");


        $("#modal-asset-claim").data("bigfunmint",bigfun_hash);
        $("#modal-asset-claim").data("address",address);
        $("#modal-asset-claim").data("asa",asset_id);

        $("#modal-asset-claim .working").hide();
        $("#modal-asset-claim .btn-asset-claim-confirm").show();
        $("#modal-asset-claim #modal-asset-claim-form").show()
        $("#modal-asset-claim #modal-asset-claim-success").hide()
        $("#modal-asset-claim #modal-asset-claim-error").hide()


        $("#modal-asset-claim #modal-asset-claim-mint").html(bigfun_hash)
        $("#modal-asset-claim #modal-asset-claim-asa").html(asset_id)

        //for inline
        if($("#modal-asset-buy").hasClass("show")){
            $("#modal-asset-buy").modal("hide");
        }


        var url = "/api/algorand/bigfun/"+bigfun_hash;

        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: function(response) {
                console.log("load claim preview respone")
                console.log(response);
                ASSETSTORE.updateHtmlHash("#modal-asset-claim #modal-asset-status-preview-wrap",response.preview_html_hash,response.preview_html)

            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.error("load claim preview ERROR")
                console.log(xhr.statusText);
                console.log(xhr.responseText);
                console.log(ajaxOptions);
                console.log(thrownError);



                $("#modal-asset-claim .working").hide();
                $("#modal-asset-claim #modal-asset-claim-form").hide()
                $("#modal-asset-claim #modal-asset-claim-error").show()

                var error_text = thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText;
                $("#modal-asset-claim #modal-asset-claim-error-text").html(error_text)
            }
        });



        $("#modal-asset-claim").modal("show");
    },


    btn_claim_confirm: async function(e) {
        e.preventDefault();

        $("#modal-asset-claim .btn-asset-claim-confirm").hide();
        $("#modal-asset-claim .working").show();

        var bigfun_hash = $("#modal-asset-claim").data("bigfunmint");
        var address = $("#modal-asset-claim").data("address");
        var asset_id = $("#modal-asset-claim").data("asa");


        console.log("ASSETSTORE.btn_claim_confirm", bigfun_hash, address, asset_id);

        //ASSETSTORE.txPurchase(asset_id,amount,ALGOBASE.user_address,address,buyprice);
        var from_address = ALGOBASE.user_address;

        console.log("txClaim-inline",bigfun_hash, address, asset_id)



        var data = {
            network: ( ALGOBASE.is_testnet ? "testnet" : "mainnet" ),
            bigfun_hash: bigfun_hash,
            from_address: from_address,
            store_address: address,
            asset_id: asset_id,
        }


        const transactionParams = ASSETSTORE.lastParams;



        var txns = [];


        //b: accept asset id!
        var txA = {
            amount: 0,
            assetIndex: data.asset_id,
            fee: 1000,
            flatFee: true,
            from: from_address,
            to: from_address,
            type: "axfer",
            suggestedParams: transactionParams
        }
        console.log("txA",txA)

        txns.push(algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(txA));


        //f: withdraws asset amount
        var txB = {
            amount: 1,
            assetIndex: data.asset_id,
            fee: 1000,
            flatFee: true,
            from: address,
            to: from_address,
            type: "axfer",
            suggestedParams: transactionParams
        }
        console.log("txA",txA)


        txns.push(algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(txB));



        // //const txn = algosdk.makePaymentTxnWithSuggestedParams(from_address, address, payprice, undefined, undefined, transactionParams);
        // const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        //     from: from_address,
        //     to: address,
        //     amount: payprice,
        //     suggestedParams: transactionParams
        // });
        // txns.push(txn)


        var blobs = false
        try {

            blobs = await ALGOBASE.wallet_sign(txns,from_address)


        } catch (e) {
            logerror("btn_claim_confirm",e)

            $("#modal-asset-claim .working").hide();

            $("#modal-asset-claim #modal-asset-claim-form").hide()
            $("#modal-asset-claim #modal-asset-claim-error").show()

            var error_text = "Wallet Signing Error";
            $("#modal-asset-claim #modal-asset-claim-error-text").html(error_text)

            return;
        } finally {
            //return;
        }



        // console.log("blobs")
        // console.log(blobs)


        data.first_round = transactionParams.firstRound
        data.blobs = blobs


        console.log("posting data")
        console.log(data)


        $.ajax({
            type: 'POST',
            url: "/api/algorand/bigfun/"+bigfun_hash+"/claim",
            data: data,
            dataType: 'json',
            success: function(response) {
                console.log("claim api call respone")
                console.log(response);

                $("#modal-asset-claim .working").hide();

                if(response.status=="good"){
                    $("#modal-asset-claim #modal-asset-claim-form").hide()
                    $("#modal-asset-claim #modal-asset-claim-success").show()

                    var link = "<a href='"+response.tx_link+"' target='_blank' rel='noopener noreferrer'>"+ALGOBASE.shortTxHtml(response.tx)+"</a>"

                    $("#modal-asset-claim #modal-asset-claim-success-tx").html(link)

                    ASSETSTORE.updateHtmlHash("#modal-asset-claim #modal-asset-claim-status-preview-wrap",response.preview_html_hash,response.preview_html)

                }else{

                    $("#modal-asset-claim #modal-asset-claim-form").hide()
                    $("#modal-asset-claim #modal-asset-claim-error").show()

                    var error_text = response.output;
                    $("#modal-asset-claim #modal-asset-claim-error-text").html(error_text)
                }




            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.error("buy ERROR")
                console.log(xhr.statusText);
                console.log(xhr.responseText);
                console.log(ajaxOptions);
                console.log(thrownError);

                $("#modal-asset-claim #modal-asset-claim-form").hide()
                $("#modal-asset-claim #modal-asset-claim-error").show()

                var error_text = thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText;
                $("#modal-asset-claim #modal-asset-claim-error-text").html(error_text)


                $("#modal-asset-claim .working").hide();
            }
        });


    },



}




$(function(){
    ASSETSTORE.init();

    $(document).on("click",".btn-asset-escrow",ASSETSTORE.btn_escrow);
    $(document).on("click",".btn-asset-escrow-confirm",ASSETSTORE.btn_escrow_confirm);

    $(document).on("click",".btn-bigfun-buy",ASSETSTORE.btn_buy);
    $(document).on("click",".btn-asset-buy-confirm",ASSETSTORE.btn_buy_confirm);
    $(document).on("click",".btn-bigfun-claim",ASSETSTORE.btn_claim);
    $(document).on("click",".btn-asset-claim-confirm",ASSETSTORE.btn_claim_confirm);


    $(document).on("click",".btn-claim-icon",function(e){
        e.preventDefault();
        $(".btn-asset-claim-confirm").click();
    });


});
