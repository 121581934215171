
import base32 from 'hi-base32';

import algosdk from 'algosdk';
import MyAlgoConnect from '@randlabs/myalgo-connect';
import { PeraWalletConnect } from "@perawallet/connect";

window.ALGOBASE = {

    sdk_client: false,
    sdk_client: false,
    wallet_client_myalgo: false,
    wallet_client_pwc: false,
    wallet_connected: false,
    wallet_type: false,

    accounts: false,
    addresses: false,
    user_address: false,

    is_testnet: false,

    ls_ua_key: "user_address",
    ls_wt_key: "wallet_type",

    base32: false,
    after_connect: false,
    after_connect_e: false,

    init: function(){
        ALGOBASE.base32 = base32;

        ALGOBASE.is_testnet = $("body").hasClass("testnet");

        ALGOBASE.user_address = localStorage.getItem(ALGOBASE.ls_ua_key);
        ALGOBASE.wallet_type = localStorage.getItem(ALGOBASE.ls_wt_key);

        // Get an instance of the WalletConnect connector
        ALGOBASE.wallet_client_pwc = new PeraWalletConnect()



        if(ALGOBASE.user_address){
            console.log("found user_address",ALGOBASE.user_address,ALGOBASE.wallet_type)
            ALGOBASE.setLocalWallet(ALGOBASE.user_address);


            if(ALGOBASE.wallet_type == "walletconnect"){
                ALGOBASE.wallet_client_pwc
                    .reconnectSession()
                    .then(ALGOBASE.pwc_connect)
                    .catch(function(error){
                        logerror("init.reconnectSession",error)
                        if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
                            console.log(error);
                        }
                    });
            }


        }


    },

    // ALGOBASE.wallet_sign(txns,from_address)
    wallet_sign: async function(txns,from_address){

        console.log("wallet_sign.txns",txns)
        console.log("wallet_sign.from_address",from_address)

        const groupId = algosdk.computeGroupID(txns);

        console.log("wallet_sign.groupId",groupId)

        for (const ttxn of txns) {
            ttxn.group = groupId;
        }

        console.log("wallet_sign.txns")
        console.log(txns)




        var blobs = false;
        if(ALGOBASE.wallet_type == "walletconnect"){

            // var std = [[
            //     {
            //         txn: txn,
            //         signers: [from_address]
            //     }
            // ]]

            var pwctxns = txns.map(function(txn){
                return {
                    txn: txn,
                    signers: [from_address],
                }
            })

            console.log("wallet_sign.pwctxns")
            console.log(pwctxns)

            var signed = await ALGOBASE.wallet_client_pwc.signTransaction([pwctxns]);

            blobs = signed.map(sx => sx.join(",")).join("|");


        }else{


            const decoded = new ALGOBASE.base32.decode.asBytes(from_address)

            const from_address_encoded_string = new Uint8Array(
              decoded.slice(
                0,
                32 //ALGORAND_ADDRESS_BYTE_LENGTH - ALGORAND_CHECKSUM_BYTE_LENGTH
              )
            ).toString()

            console.log("wallet_sign.txns from_address_encoded",from_address)
            console.log(from_address_encoded_string)
            txns = txns.filter((txn)=>txn.from.publicKey.toString() == from_address_encoded_string);

            console.log("wallet_sign.txns from_address")
            console.log(txns)




            const myalgotxns = txns.map((ttxn)=> ttxn.toByte());

            //await ALGOBASE.connectWallet();

            console.log("wallet_sign.myalgotxns")
            console.log(myalgotxns)
            var myAlgoWallet = ALGOBASE.algoWallet();
            var signed = await myAlgoWallet.signTransaction(myalgotxns)


            console.log("signed")
            console.log(signed)
            blobs = signed.map(({blob})=>blob.join(",")).join("|");


        }


        return blobs
    },

    // ALGOBASE.pwc_connect
    pwc_connect: function(accounts){

        ALGOBASE.user_address = accounts[0];

        localStorage.setItem(ALGOBASE.ls_ua_key, ALGOBASE.user_address);
        localStorage.setItem(ALGOBASE.ls_wt_key, "walletconnect");
        ALGOBASE.wallet_type = "walletconnect";
        Cookies.set('user_address', ALGOBASE.user_address)

        ALGOBASE.setLocalWallet(ALGOBASE.user_address);

        ALGOBASE.afterAskWallet();

    },
    pwc_reconnect: function(accounts){

        // ALGOBASE.user_address = accounts[0];

        // localStorage.setItem(ALGOBASE.ls_ua_key, ALGOBASE.user_address);
        // localStorage.setItem(ALGOBASE.ls_wt_key, "walletconnect");
        // ALGOBASE.wallet_type = "walletconnect";
        // Cookies.set('user_address', ALGOBASE.user_address)

        // ALGOBASE.setLocalWallet(ALGOBASE.user_address);

    },


    // ALGOBASE.clear_local_data()
    // ALGOBASE.clear_local_data(true)
    clear_local_data: function(all){

        if(ALGOBASE.wallet_type == "walletconnect" || all){
            ALGOBASE.wallet_client_pwc.disconnect().catch((error) => {
                logerror("clear_local_data",error)
                console.log(error);
            });
        }

        localStorage.removeItem(ALGOBASE.ls_ua_key);
        localStorage.removeItem(ALGOBASE.ls_wt_key);
        Cookies.remove('user_address');
        //ALGOBASE.wallet_client_pwc.killSession();

        window.location.reload()
    },

    // ALGOBASE.set_remote_wallet(address)
    set_remote_wallet:async function(address){
        ALGOBASE.user_address = address;
        localStorage.setItem(ALGOBASE.ls_ua_key, ALGOBASE.user_address);
        localStorage.setItem(ALGOBASE.ls_wt_key, "remote");
        Cookies.set('user_address', ALGOBASE.user_address)
        ALGOBASE.setLocalWallet(ALGOBASE.user_address);
    },

    btn_connect_wallet_myalgo: async function(e){
        e.preventDefault();

        await ALGOBASE.connectMyAlgoWallet();

        //window.location = "/algorand/addresses/"+user_address;

        ALGOBASE.setLocalWallet(ALGOBASE.user_address);
        //window.location.refresh();
    },


    btn_connect_wallet_walletconnect: async function(e){
        e.preventDefault();

        ALGOBASE.wallet_client_pwc
            .connect()
            .then(ALGOBASE.pwc_connect)
            .catch(function(error){
                logerror("btn_connect_wallet_walletconnect",error)
                if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
                    console.log(error);
                }
            });


            // .then(function(newAccounts) {
            //     console.log("handleConnectWalletClick",newAccounts)

            //     //ALGOBASE.wallet_client_pwc.connector.on("disconnect", handleDisconnectWalletClick);

            //     accountAddress = newAccounts[0];
            //     console.log("connected pera",accountAddress)
            //     //$("#connect").html("Disconnect");
            // })
        //window.location.refresh();
    },



    setLocalWallet: function(user_address){
        //$(".btn-connect-login").hide();

        $("body").addClass("bfw-connected");

        //$(".btn-connect-dropdown").hide();
        //$(".btn-my-wallet").removeClass("d-none");

        //var bolt = '<i class="fa fa-bolt" aria-hidden="true"></i>';
        //var nav_html = bolt + ALGOBASE.shortAddressssHtml(ALGOBASE.user_address);
        var nav_html = ALGOBASE.shortAddressssHtml(ALGOBASE.user_address);

        $(".btn-my-wallet > a").html(nav_html);
        $(".btn-my-wallet > a").attr("title","Connected with " + ALGOBASE.wallet_type )
        //$(".btn-my-wallet").attr("href","/addresses/"+ALGOBASE.user_address);

    },

    //ALGOBASE.shortAddressssHtml(address)
    shortAddressssHtml: function(address){

        var limit = 6;
        var p1 = address.substring(0,limit);
        var p2 = address.substring(address.length-limit);
        var sss = p1 + "..." +  p2;;

        //var c = "font-monospace p-1 pb-1 rounded-2 border theme-bg text-break";
        //var css = ""

        var c = "font-monospace p-1 pb-1 rounded-2 text-light"
        var hash = md5(address)
        var start = hash.substring(0,6)
        var stop = hash.substring(26)
        var css = `background:linear-gradient(90deg,#${start},#${stop})`



        return `<span class="${c}" style="${css}">${sss}</span>`;
    },

    //ALGOBASE.shortTxHtml(response.tx)
    shortTxHtml: function(tx){

        var limit = 6;
        var p1 = tx.substring(0,limit);
        var p2 = tx.substring(tx.length-limit);
        var sss = p1 + "..." +  p2;;

        var c = "font-monospace p-1 pb-1 rounded-2 border theme-bg text-break";

        return `<span class="${c}">${sss}</span>`;
    },






    getAlgoExplorerApiBaseUrl: function() {
        return ALGOBASE.is_testnet ? "https://node.testnet.algoexplorerapi.io" : "https://node.algoexplorerapi.io"
    },


    algoClient: function() {
        if(ALGOBASE.sdk_client){
            return ALGOBASE.sdk_client;
        }

        ALGOBASE.sdk_client = new algosdk.Algodv2("",ALGOBASE.getAlgoExplorerApiBaseUrl(),"");

        return ALGOBASE.sdk_client;
    },

    algoWallet: function() {
        if(ALGOBASE.wallet_client_myalgo){
            return ALGOBASE.wallet_client_myalgo;
        }

        ALGOBASE.wallet_client_myalgo = new MyAlgoConnect(true);
        return ALGOBASE.wallet_client_myalgo
    },

    //ALGOBASE.connectMyAlgoWallet()
    connectMyAlgoWallet: async function(){
        if( ! ALGOBASE.wallet_connected){
            ALGOBASE.accounts = await ALGOBASE.algoWallet().connect();
            ALGOBASE.addresses = ALGOBASE.accounts.map(account => account.address);

            ALGOBASE.wallet_connected = true;


            ALGOBASE.user_address = ALGOBASE.addresses[0];

            localStorage.setItem(ALGOBASE.ls_ua_key, ALGOBASE.user_address);
            localStorage.setItem(ALGOBASE.ls_wt_key, "myalgowallet");

            ALGOBASE.wallet_type = "myalgowallet";
            Cookies.set('user_address', ALGOBASE.user_address)


            ALGOBASE.afterAskWallet();
        }

        // $(".btn-connect-wallet").html("<icon class='fa fa-bolt'></icon>")
        // $(".btn-connect-myalgo").attr("title","connected!")
    },

    //ALGOBASE.afterAskWallet()
    afterAskWallet: function(){
        $("#modal-connect").modal("hide");
        if(ALGOBASE.after_connect == "btn_buy"){
            //start buy
            ASSETSTORE.btn_buy(ALGOBASE.after_connect_e)
        }
    },


    //ALGOBASE.askWallet()
    askWallet: function(e,next_step){
        ALGOBASE.after_connect_e = e;
        ALGOBASE.after_connect = next_step;
        $("#modal-connect").modal("show");
    }


}




$(function(){
    ALGOBASE.init();

    $(".btn-connect-myalgo").on("click",ALGOBASE.btn_connect_wallet_myalgo);
    $(".btn-connect-walletconnect").on("click",ALGOBASE.btn_connect_wallet_walletconnect);
    //$(".btn-connect-login").on("click",ALGOBASE.btn_connect_wallet_myalgo);
    $(".btn-wallet-forget").on("click",ALGOBASE.clear_local_data);



});

document.addEventListener("turbolinks:load", function() {

    if( ALGOBASE.user_address ){
        ALGOBASE.setLocalWallet(ALGOBASE.user_address);
    }


    // if( ALGOBASE.wallet_connected){

    //     $(".btn-connect-myalgo").html("<icon class='fa fa-bolt'></icon>")
    //     $(".btn-connect-myalgo").attr("title","connected!")

    // }
})




function md5(inputString) {
    var hc="0123456789abcdef";
    function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
    function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
    function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
    function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
    function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
    function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
    function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
    function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
    function sb(x) {
        var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
        for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
        blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
    }
    var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
    for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
        a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
        b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
        c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
        d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
        a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
        b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
        c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
        d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
        a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
        b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
        c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
        d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
        a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
        b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
        c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
        d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
        a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
        b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
        c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
        d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
        a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
        b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
    }
    return rh(a)+rh(b)+rh(c)+rh(d);
}