//import 'clipboard-js';

$(function(){

    // //$("#modal-share").modal("show")

    // var found = $("#modal-share");

    // if(found.length > 0){
    //     console.log("found share")
    // }

    // $("#modal-share").modal("show")

    var current_share_url = false;

    $(document).on('click', ".btn-share-modal", function() {
        var url = $(this).data('url')

        current_share_url = url

        $("#modal-share input").val(current_share_url)


        $("#modal-share .btn-share-facebook").attr('href',"https://www.facebook.com/sharer.php?u="+current_share_url)
        $("#modal-share .btn-share-telegram").attr('href',"https://t.me/share/url?url="+current_share_url)
        $("#modal-share .btn-share-twitter").attr('href',"https://twitter.com/intent/tweet?url="+current_share_url)
        $("#modal-share .btn-share-whatsapp").attr('href',"https://wa.me/?text="+current_share_url)


        $("#modal-share").modal("show")

    });

    //new Clipboard('.btn-share-copy');

    $(document).on('click', ".btn-share-copy", function() {
        console.log("share copy",current_share_url)
        window.clipboard.copy(current_share_url);

    });




});


//https://twitter.com/intent/tweet?url=https://url
//tgram
//https://t.me/share/url?url=https://url
//https://www.facebook.com/sharer.php?u=https://url
//https://wa.me/?text=https://url