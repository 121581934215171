

var error_progress = false;
function logerror(prefix,error){

    var msg = prefix + "\n\n##message\n```" +error.message + "```\n\n##stack\n```" + error.stack + "```";

    console.log("error",msg)

    logerrormsg(msg)
}

window.logerror = logerror

function logerrormsg(msg){
    if(error_progress){
        return;
    }
    error_progress = true;
    $.ajax({
        type: "POST",
        url: "/api/error",
        data: {
            msg: msg
        },
        success: function(d){
            error_progress = false;
        }
    })
}

window.logerrormsg = logerrormsg